.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);
  height: 100%;
  justify-content: center;
  margin: 0 var(--space-2);
}

.tokenButton {
  display: flex;
  border-radius: 6px;
  padding: 0px var(--space-1) 0px var(--space-1);
  background-color: var(--color-border-background);
  margin: var(--space-1);
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-left: 0;
  margin-right: 0;
  align-self: stretch;
}

.sep5 {
  height: 42px;
}

[data-theme='dark'] .allocationBadge :global .MuiBadge-dot {
  background-color: var(--color-primary-main);
}

.redeemButton {
  margin-left: var(--space-1);
  padding: calc(var(--space-1) / 2) var(--space-1);
}
